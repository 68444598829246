.container {
    font-family: Helvetica, Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 42px;
    letter-spacing: 7px;
    color: #2d98b5;
}

.post {
    width: 35%;
    box-shadow: 0px 0px 15px 1px #bdbdbd;
    border-radius: 10px;
    margin: 1rem;
    padding: 1rem
}

.post:hover {
    box-shadow: 0px 0px 15px 3px #bdbdbd;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -10px;
}

.date {
    display: flex;
    justify-content: center;
    font-size: 13px;
}

.add {
    background-color: #2d98b5;
    font-size: 24px;
    padding: 0.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    cursor: pointer;
    user-select: none;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}

.input {
    width: 40%;
    padding: 5px;
}

.textarea {
    resize: none;
    font-family: Helvetica, Arial, sans-serif;
    padding: 5px;
    width: 75%;
    height: 4rem;
}

.error {
    color: red;
    font-size: 14px;
    margin-bottom: 15px;
}

.votes {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    user-select: none;
}

.arrow {
    cursor: pointer;
    font-size: 18px;
}
